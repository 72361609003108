<template>
  <header class="header">
    <inline-svg :src="require('../../../assets/icon/svg/u-power-logo.svg')" />
  </header>
</template>
<script>
export default {
  name: "header.vue",
};
</script>
