<template>
  <main id="full"></main>
</template>
<script>
export default {
  name: "DownloadApp",
  data() {
    return {
      isCloseConfirmCard: false,
      isProcessing: false,
      userAgent: "",
      connectorStatus: {
        Available: 1,
        Unavailable: 2,
        Preparing: 3,
        Charging: 4,
        SuspendedEVSE: 5,
        SuspendedEV: 6,
        Finishing: 7,
        Faulted: 8,
        Remove: 9,
        Reserved: 10,
        Offline: 11,
        UnKnow: 12,
      },
      unitPrice: {
        pricingPlanId: "",
        unitPrice: 0,
      },
      currentPrice: {
        pricingPlanId: "",
        unitPrice: 0,
      },
      currentConnectorStatus: {
        connectorId: "",
        status: 0,
      },
      isLoading: false,
      isModalOpen: false,
      connector: {
        connectorId: "",
        stationId: "",
        boxId: "",
        connector: "",
        current: 0,
        position: 1,
        connectorType: {
          connectorTypeId: 0,
          connectorType: "",
        },
        flag: 1,
        status: 1,
        available: 1,
      },
    };
  },
  async mounted() {
    this.checkDeviceOs();
  },
  methods: {
    redirectToAppPage() {
      // const googlePlayUrl =
      //   "https://play.google.com/store/apps/details?id=com.u_power";

      const appStoreUrl = "https://apps.apple.com/tw/app/u-power/id1612379206";

      if (this.userAgent === "Android") {
        // window.location = googlePlayUrl;
        this.checkAppIsExist();
      }
      if (this.userAgent === "IOS") {
        window.location = appStoreUrl;
      }

      if (this.$route.connectorId) {
        this.$router.push({
          name: "Connector",
          query: {
            connectorId: this.$route.connectorId,
            stationId: this.$route.stationId,
          },
        });
        return;
      }
      if (this.$route.chargebox) {
        this.$router.push({
          name: "Connector",
          query: {
            chargebox: this.$route.chargebox,
            connectorno: this.$route.connectorno,
          },
        });
        return;
      }
      this.$router.push({
        name: "ConnectorSearch",
      });
    },
    checkAppIsExist() {
      const openAppUrl = "upower://";
      // const googlePlayUrl =
      //   "https://play.google.com/store/apps/details?id=com.u_power";
      const openGoogleMarket = "market://details?id=com.u_power";
      const appStoreUrl = "https://apps.apple.com/tw/app/u-power/id1612379206";

      // 避免啟動失敗的計時器
      var timer = setTimeout(() => {
        if (this.userAgent === "Android") {
          window.location.href = openGoogleMarket;
          return;
        }
        if (this.userAgent === "IOS") {
          window.location = appStoreUrl;
          return;
        }
      }, 1000);

      if (this.userAgent === "Android") {
        window.clearTimeout(timer);
        window.location.href = openAppUrl;
        return;
      }
      if (this.userAgent === "IOS") {
        window.clearTimeout(timer);
        window.location = appStoreUrl;
      }

      // 用 onblur 來確認有沒有多跳一個是否啟動 APP 的確認視窗
      if (this.userAgent === "Android") {
        window.onblur = () => {
          window.clearTimeout(timer);
          window.close();
        };
      }
    },
    checkDeviceOs() {
      let userAgent = navigator.userAgent;

      if (userAgent.match(/Windows Phone/i)) {
        // Windows Phone
        this.userAgent = "Android";
        return;
      } else if (userAgent.match(/Android/i)) {
        if (userAgent.match(/Chrome/i)) {
          // Android + Chrome
          setTimeout(() => {
            if (!document.webkitHidden) {
              this.userAgent = "Android";
              return;
            }
          }, 1000);
        } else {
          // Android + 非 Chrome
          this.userAgent = "Android";
          return;
        }
      } else if (userAgent.match(/(iPhone|iPad|iPod)/i)) {
        setTimeout(() => {
          this.userAgent = "IOS";
          return;
        }, 25);
      } else if (userAgent.match(/(Macintosh|Mac)/i)) {
        setTimeout(() => {
          this.userAgent = "IOS";
          return;
        }, 25);
      } else {
        //其他
        let loadDateTime = new Date();
        setTimeout(() => {
          let timeOutDateTime;
          timeOutDateTime = new Date();
          if (timeOutDateTime - loadDateTime < 5000) {
            this.userAgent = "Android";
            return;
          }
        }, 1000);
      }
    },
  },
  watch: {
    userAgent() {
      if (this.userAgent !== "") {
        this.redirectToAppPage();
      }
    },
  },
};
</script>

<style scoped></style>
