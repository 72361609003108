import Vue from "vue";
import VueRouter from "vue-router";
import NotFound from "../views/NotFound.vue";
import Maintenance from "../views/Maintenance.vue";
import ConnectorSearch from "../views/ConnectorSearch.vue";
import DownloadAPP from "../views/DownloadAPP.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "root",
    redirect: "/search",
  },
  {
    path: "/search",
    name: "ConnectorSearch",
    component: ConnectorSearch,
  },
  {
    path: "/download",
    name: "AppDownload",
    component: DownloadAPP,
  },
  {
    path: "/connector",
    name: "Connector",
    component: () => import("../views/ConnectorAPP"),
  },
  {
    path: "/hint/:stationId/:connectorId",
    name: "Hint",
    component: () => import("../views/Hint.vue"),
    children: [
      {
        path: "plug",
        name: "PlugHint",
        components: {
          default: () => import("../components/Messages/PlugMessage.vue"),
        },
      },
      {
        path: "card-auth",
        name: "CardAuth",
        components: {
          default: () =>
            import("../components/Messages/CardAuthSuccessMessage.vue"),
        },
      },
      {
        path: "check",
        name: "CheckConnector",
        components: {
          default: () =>
            import("../components/CheckConnector/CheckConnector.vue"),
        },
      },
      {
        path: "failed",
        name: "CardFailed",
        components: {
          default: () =>
            import("../components/Messages/CardAuthFailedMessage.vue"),
        },
      },
      {
        path: "error",
        name: "ErrorHint",
        components: {
          default: () => import("../components/Messages/ErrorMessage"),
        },
      },
      {
        path: "being-used",
        name: "BeingUsedHint",
        components: {
          default: () => import("../components/Messages/BeingUsedMessage.vue"),
        },
      },
    ],
  },
  {
    path: "/payment/:stationId/:connectorId",
    name: "Payment",
    component: () => import("../views/Payment.vue"),
    redirect: "/payment/:stationId/:connectorId/credit-card",
    children: [
      {
        path: "credit-card",
        name: "CreditCard",
        components: {
          default: () => import("../components/Payment/CreditCard.vue"),
        },
      },
    ],
  },
  {
    path: "/charging/:chargingId/:stationId/:connectorId/progress",
    name: "Charging-progress",
    component: () => import("../views/Progress.vue"),
  },

  {
    path: "/charging/:chargingId/:stationId/:connectorId/progress/stop-error",
    name: "StopErrorHint",
    component: () => import("../components/Messages/StopErrorMessage.vue"),
  },
  {
    path: "/charging/:chargingId/:stationId/:connectorId/progress/progress-error",
    name: "ProgressErrorHint",
    component: () => import("../components/Messages/ProgressErrorMessage.vue"),
  },
  {
    path: "/charging/:chargingId/receipt",
    name: "Charging-receipt",
    component: () => import("../views/Receipt.vue"),
  },
  {
    path: "/policy",
    name: "Policy",
    component: () => import("../views/Policy.vue"),
    redirect: "/policy/privacy",
    children: [
      {
        path: "privacy",
        name: "Privacy",
        components: {
          default: () => import("../components/Policy/Privacy.vue"),
        },
      },
      {
        path: "service",
        name: "Service",
        components: {
          default: () => import("../components/Policy/Service.vue"),
        },
      },
    ],
  },
  {
    path: "/point",
    name: "PointQuery",
    component: () => import("../views/PointQuery.vue"),
  },
  {
    path: "/point/query",
    name: "PointResult",
    component: () => import("../views/PointResult.vue"),
    redirect: "/point/query/earned",
    children: [
      {
        path: "earned",
        name: "EarnedPoint",
        components: {
          default: () => import("../components/Point/EarnedPoint.vue"),
        },
      },
      {
        path: "transactions",
        name: "PointTransactions",
        components: {
          default: () => import("../components/Point/PointTransactions.vue"),
        },
      },
    ],
  },
  {
    path: "/cardlink",
    name: "CardLink",
    component: () => import("../views/CardLink.vue"),
  },
  {
    path: "/cardlink/SMSverify",
    name: "SMSverify",
    components: {
      default: () => import("../components/Verify/SMSVerify.vue"),
    },
  },
  {
    path: "/cardlink/bindingSuccess",
    name: "BindingSuccess",
    components: {
      default: () => import("../components/Verify/BindingSuccess.vue"),
    },
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance,
  },
  {
    path: "/autoshow2024",
    name: "AutoShow2024",
    redirect: () => {
      return {
        name: "CarsIntroduction",
      };
    },
    component: () => import("../views/AutoShow2024.vue"),
    children: [
      {
        path: "main",
        name: "AutoShow2023-homePage",
        component: () => import("../components/AutoShow2023/HomePage.vue"),
      },
    ],
  },
  {
    path: "/autoshow2023",
    name: "AutoShow2023",
    redirect: () => {
      return {
        name: "CarsIntroduction",
      };
    },
    component: () => import("../views/AutoShow2023.vue"),
    children: [
      {
        path: "cars-introduction",
        name: "CarsIntroduction",
        component: () =>
          import("../components/AutoShow2023/CarsIntroduction-menu.vue"),
      },
      {
        path: "cars-introduction/:car/detail",
        name: "CarsIntroduction-detail",
        component: () =>
          import("../components/AutoShow2023/CarsIntroduction-detail.vue"),
      },
    ],
  },
  {
    path: "*",
    name: "not-found",
    component: NotFound,
  },
];

const router = new VueRouter({
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
