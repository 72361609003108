<template>
  <main id="full">
    <section class="section-main py-0">
      <div class="section-main__action">
        <MaintenanceMessage />
      </div>
    </section>
  </main>
</template>
<script>
import MaintenanceMessage from "../components/Messages/MaintenanceMessage.vue";
export default {
  name: "Maintenance",
  components: { MaintenanceMessage },
  data() {
    return {
      isClearInterval: false,
    };
  },
  methods: {},
};
</script>
