import axios from "axios";
import router from "../router";

const baseURL = process.env.VUE_APP_BASE_URL;

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.response.use(
  function (res) {
    return res;
  },
  function (error) {
    // Do something with request error
    // console.log(error.response.data.message);
    if (
      error.response.status === 500 &&
      error.response.data.Code === "000102"
    ) {
      router.push({
        name: "Maintenance",
        params: { date: error.response.data.Message },
      });
    } else if (error.response.status) {
      switch (error.response.status) {
        case 500:
          console.log("程式發生問題");
          router.push({
            name: "ErrorHint",
          });
          break;
        default:
          console.log(error.message);
      }
    }

    return Promise.reject(error);
  }
);

export const apiHelper = axiosInstance;
