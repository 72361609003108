<template>
  <div class="section-main__content">
    <inline-svg :src="require('../../assets/icon/svg/404.svg')" />
    <div class="section-main__title">
      <h1 class="not-found">OOPS！我們迷路了</h1>
    </div>
    <p class="not-found">請使用其它充電裝置重新掃描 QRcode</p>
    <p class="not-found mt-5">
      <strong>{{ time }}</strong> 秒後將自動跳轉至首頁
    </p>
  </div>
</template>
<script>
export default {
  name: "NotFoundMessage.vue",
  props: {
    isClearInterval: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      timer: null,
      time: 5,
    };
  },
  mounted() {
    this.timer = setInterval(this.countdown, 1 * 1000);
  },
  methods: {
    countdown() {
      this.time--;
      if (this.time === 0) {
        this.$router.push({
          name: "ConnectorSearch",
        });
        clearInterval(this.timer);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  watch: {
    isClearInterval: function (newValue) {
      if (newValue === true) {
        clearInterval(this.timer);
      }
    },
  },
};
</script>
