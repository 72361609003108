import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { InlineSvgPlugin } from "vue-inline-svg";
import {
  isGuiNumberValid,
  isEInvoiceCellPhoneBarcodeValid,
} from "taiwan-id-validator";
import VueCardFormat from "vue-credit-card-validation";
import VueQrcodeReader from "vue-qrcode-reader";
import * as math from "mathjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(tz);
Vue.prototype.dayjs = dayjs;
Vue.prototype.$math = math;

Vue.use(VueQrcodeReader);
Vue.use(VueCardFormat);
Vue.use(InlineSvgPlugin);
Vue.use(isGuiNumberValid, isEInvoiceCellPhoneBarcodeValid);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
