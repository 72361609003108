<template>
  <main id="full">
    <section class="section-main py-2">
      <div class="section-main__action">
        <NotFoundMessage :is-clear-interval="isClearInterval" />
        <button
          @click="handleClick()"
          class="button is-fullwidth is-info is-rounded"
          type="button"
        >
          回到首頁
        </button>
      </div>
    </section>
  </main>
</template>
<script>
import NotFoundMessage from "../components/Messages/NotFoundMessage.vue";
export default {
  name: "NotFound",
  components: { NotFoundMessage },
  data() {
    return {
      isClearInterval: false,
    };
  },
  methods: {
    handleClick() {
      this.isClearInterval = true;
      this.$router.push({
        name: "ConnectorSearch",
      });
    },
  },
};
</script>
