<template>
  <div id="app" v-if="!isAutoShowPage">
    <div id="top" v-if="$route.path === '/'">
      <router-view />
    </div>
    <div id="mobile" v-else>
      <InstallBanner
        @close="handleCloseBanner"
        :user-agent="userAgent"
        :is-banner-show="isBannerShow"
      />
      <Header />
      <router-view />
      <Footer />
    </div>
  </div>
  <div v-else><router-view /></div>
</template>

<script>
import DeviceDetector from "device-detector-js";
import InstallBanner from "./components/UI/Banner/InstallBanner.vue";
import Header from "./components/UI/Header/header.vue";
import Footer from "./components/UI/Footer/Footer.vue";
export default {
  name: "App.vue",
  data() {
    return {
      userAgent: "",
      isBannerShow: false,
      isAutoShowPage: false,
    };
  },
  components: { InstallBanner, Footer, Header },
  async created() {
    this.checkIsInstallApp();
  },
  methods: {
    checkIsInstallApp() {
      const deviceDetector = new DeviceDetector();
      let userAgent = navigator.userAgent;
      const device = deviceDetector.parse(userAgent).device.type;
      if (userAgent.match(/Windows Phone/i)) {
        // Windows Phone
        this.isBannerShow = true;
        this.userAgent = "Android";
        return;
      } else if (userAgent.match(/Android/i)) {
        if (userAgent.match(/Chrome/i)) {
          // Android + Chrome
          this.isBannerShow = false;
          setTimeout(() => {
            if (!document.webkitHidden) {
              this.isBannerShow = true;
              this.userAgent = "Android";
              return;
            }
          }, 2000);
        } else {
          // Android + 非 Chrome
          // this.isBannerShow = true
          this.isBannerShow = false;
          this.userAgent = "Android";
          return;
        }
      } else if (userAgent.match(/(iPhone|iPad|iPod)/i)) {
        setTimeout(() => {
          this.isBannerShow = true;
          this.userAgent = "IOS";
          return;
        }, 2000);
        this.isBannerShow = false;
      } else if (device === "desktop") {
        this.isBannerShow = false;
        return;
      } else {
        //其他
        let loadDateTime = new Date();
        setTimeout(() => {
          let timeOutDateTime;
          timeOutDateTime = new Date();
          if (timeOutDateTime - loadDateTime < 5000) {
            this.userAgent = "Android";
            this.isBannerShow = true;
            return;
          }
        }, 2000);
        this.isBannerShow = false;
      }
    },
    handleCloseBanner() {
      this.isBannerShow = false;
    },
  },
  watch: {
    $route() {
      // console.log(this.$route.path.includes("autoshow2023"));
      this.isAutoShowPage =
        this.$route.path.includes("autoshow2023") ||
        this.$route.path.includes("autoshow2024");
    },
  },
};
</script>
<style lang="scss">
@import "./assets/scss/main";
@import "./assets/fonts/css/fontello.css";
</style>
