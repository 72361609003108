<template>
  <div class="install-banner" :class="{ show: isBannerShow }">
    <span class="install-banner-close" @click="handleClose">&#10005;</span>
    <inline-svg :src="require('../../../assets/icon/svg/app-icon.svg')" />
    <p class="install-banner-content">
      下載 U-POWER 應用程式<br />
      <span> 獲得最佳充電體驗及優惠費率 </span>
    </p>
    <template v-if="userAgent === 'Android'">
      <a
        href="https://play.google.com/store/apps/details?id=com.u_power"
        target="_blank"
      >
        前往安裝</a
      >
    </template>
    <template v-if="userAgent === 'IOS'">
      <a
        href="https://apps.apple.com/tw/app/u-power/id1612379206"
        target="_blank"
      >
        前往安裝</a
      >
    </template>
  </div>
</template>
<script>
export default {
  name: "InstallBanner.vue",
  props: {
    userAgent: { type: String, require: true },
    isBannerShow: { type: Boolean, require: true },
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
  },
  watch: {
    initialUserAgent(newValue) {
      this.userAgent = newValue;
    },
  },
};
</script>
