import * as math from "mathjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(tz);

export const timeFilter = {
  filters: {
    totalTime(totalTime) {
      let diffMins = Math.floor(totalTime / 60);
      totalTime = diffMins;
      return totalTime;
    },
    dateTime(dateTime) {
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      };
      dateTime = new Date(dateTime).toLocaleString("zh-TW", options);
      return dateTime;
    },
    formatHHMMSS(formatHHMMSS) {
      formatHHMMSS = dayjs
        .utc(formatHHMMSS)
        .tz("Asia/Taipei")
        .local()
        .format("HH:mm:ss");
      return formatHHMMSS;
    },
    formatForMaintenance(formatForMaintenance) {
      const dayToChineseFilter = (day) => {
        switch (day) {
          case 1: {
            return "(一)";
          }
          case 2: {
            return "(二)";
          }
          case 3: {
            return "(三)";
          }
          case 4: {
            return "(四)";
          }
          case 5: {
            return "(五)";
          }
          case 6: {
            return "(六)";
          }
          case 7: {
            return "(日)";
          }
          default:
            return;
        }
      };
      // 將"2022-08-28T16:00:00Z"格式轉成一包dayjs的物件
      const formatDate = dayjs(formatForMaintenance);

      // 轉換物件內的星期變中文
      const day = dayToChineseFilter(formatDate.$W);

      formatForMaintenance = dayjs
        .utc(formatForMaintenance)
        .tz("Asia/Taipei")
        .local()
        .format(`MM/DD ${day} HH:mm`);

      return formatForMaintenance;
    },
    convertMillisecondsDurationsToTime(milliseconds) {
      const leftPad = (num) => (num > 9 ? num : "0" + num);
      let diffHrs = Math.floor((milliseconds % 86400000) / 3600000);
      let diffMins = Math.floor(((milliseconds % 86400000) % 3600000) / 60000);
      let diffSecs = Math.floor(
        (((milliseconds % 86400000) % 3600000) % 60000) / 1000
      );
      if (diffHrs > 0) {
        return (milliseconds = `${leftPad(diffHrs)} 時 ${leftPad(
          diffMins
        )} 分 ${leftPad(diffSecs)} 秒`);
      } else if (diffHrs === 0 && diffMins > 0) {
        return (milliseconds = `${leftPad(diffMins)} 分 ${leftPad(
          diffSecs
        )} 秒`);
      } else if (diffHrs === 0 && diffMins === 0 && diffSecs > 0) {
        return (milliseconds = `${leftPad(diffSecs)} 秒`);
      } else {
        return (milliseconds = "-");
      }
    },
  },
};

export const powerFilter = {
  filters: {
    toFixed(power) {
      if (Number.isInteger(power)) {
        return power;
      }
      power = math.round(power, 1);
      return power;
    },
    toFixedInteger(amount) {
      if (Number.isInteger(amount)) {
        return amount;
      }
      amount = math.round(amount);
      return amount;
    },
  },
};

export const energyFilter = {
  filters: {
    energyToKwh(energy) {
      energy = math.round(Number(energy) * 0.001, 2);
      return energy;
    },
  },
};
