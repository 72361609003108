import { apiHelper } from "../utils/helpers";

export default {
  connector: {
    getByConnectorId({ connectorid }) {
      return apiHelper.get(`/connector?connectorid=${connectorid}`);
    },
    getByConnectorCode({ connectorcode }) {
      return apiHelper.get(`/connector?connectorcode=${connectorcode}`);
    },
    getStatus({ connectorid }) {
      return apiHelper.get(
        `charging/connector/status?connectorid=${connectorid}`
      );
    },
    getByBoxCodeConnectorNo({ chargebox, connectorno }) {
      return apiHelper.get(
        `/connector?chargebox=${chargebox}&connectorno=${connectorno}`
      );
    },
  },
};
