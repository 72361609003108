<template>
  <div class="section-main__content">
    <inline-svg :src="require('../../assets/icon/svg/maintenance.svg')" />
    <div class="section-main__title">
      <h1 class="not-found">系統維護通知</h1>
    </div>
    <p class="not-found" v-if="maintenanceDate.length > 0">
      為提供您更好的服務及用戶體驗，<br />
      <span class="main-message">
        <strong>
          {{ formatMaintenanceDate[0] | formatForMaintenance }} ~
        </strong>
        <strong>
          {{ formatMaintenanceDate[1] | formatForMaintenance }}
        </strong>
      </span>
      將進行系統升級，期間 App 無法使用，
      <br />
      造成您的不便敬請見諒。
      <br />
      <br />

      如有其他疑問，請聯絡客服人員：0809-0809-81
    </p>
    <p class="not-found" v-if="maintenanceDate == ''">
      為提供您更好的服務及用戶體驗，<br />
      目前正在進行系統升級，期間 App 無法使用，
      <br />
      造成您的不便敬請見諒。
      <br />
      <br />

      如有其他疑問，請聯絡客服人員：0809-0809-81
    </p>
  </div>
</template>
<script>
import { timeFilter } from "../../utils/mixins";

export default {
  name: "MaintenanceMessage.vue",
  props: {
    isClearInterval: {
      type: Boolean,
      require: true,
    },
  },
  mixins: [timeFilter],
  data() {
    return {
      maintenanceDate: "",
    };
  },
  mounted() {
    this.maintenanceDate = this.$route.params.date;
  },
  methods: {},
  computed: {
    formatMaintenanceDate() {
      const splitDate = this.maintenanceDate.split(",");
      return splitDate;
    },
  },
};
</script>
