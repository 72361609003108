<template>
  <footer class="footer">
    <div class="footer-wrapper">
      <div class="container footer-wrapper_info">
        <inline-svg
          :src="require('../../../assets/icon/svg/ev-gallop.svg')"
          class="logo-company"
        />
        <div class="footer-wrapper_service">
          客服電話：0809-0809-81 / LINE：<a
            href="https://line.me/R/ti/p/@u-powertw?from=page"
            target="_blank"
            >@u-powertw</a
          >
        </div>
        <div class="footer-text_copyright">
          ©<span id="year">2022</span> EVGallop Mobility System Corporation. All
          Rights Reserved.
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer.vue",
};
</script>
