<template>
  <main id="full">
    <section class="section my-0">
      <h1 class="search-form__title">充電槍配對</h1>
    </section>
    <section class="section-main py-0">
      <div class="search-wrapper">
        <form
          class="search-form"
          @submit.prevent="handleSubmit"
          @change="changeHandler"
          novalidate
        >
          <div class="search-form__item camera">
            <p class="error">{{ error }}</p>
            <qrcode-stream
              :camera="camera"
              @decode="onDecode"
              @init="onInit"
              :track="paintBoundingBox"
              ><div class="loading-indicator" v-if="loading">
                <inline-svg
                  :src="require('../assets/icon/svg/loading.svg')"
                  class="loading"
                />
                <p>QR Code Scanner 載入中...</p>
              </div>
              <div v-if="validationSuccess" class="validation-success">
                即將前往充電頁...
              </div>
              <div v-if="isValid === false" class="validation-failure">
                <span>查無此充電槍</span>
              </div></qrcode-stream
            >
          </div>
          <div class="search-form__item">
            <p>
              <span>請掃描 QR Code</span>
            </p>
            <p>
              <span>或</span>
            </p>
          </div>
          <div class="search-form__item">
            <input
              type="text"
              title="請輸入充電槍代碼"
              required
              maxlength="6"
              id="connectorCode"
              v-model="connectorCode"
              inputmode="numeric"
              :class="isInvalid ? 'invalid' : ''"
            />
            <small v-if="isInvalid">請輸入有效的充電槍代碼</small>
            <label for="connectorCode">請輸入充電槍代碼</label>
          </div>
          <div class="section-main__action" style="margin-top: 0">
            <button
              @blur="handleSubmitInvalid"
              class="button is-fullwidth is-rounded"
              type="submit"
            >
              {{ isProcessing ? "充電槍配對中..." : "下一步" }}
            </button>
          </div>
        </form>
      </div>
    </section>
  </main>
</template>
<script>
import connectorAPI from "../apis/connector";

export default {
  name: "ConnectorSearch.vue",
  data() {
    return {
      connectorCode: "",
      isInvalid: false,
      isProcessing: false,
      error: "",
      result: "",
      loading: false,
      camera: "auto",
      isValid: undefined,
    };
  },

  methods: {
    async onDecode(content) {
      this.result = content;
      this.turnCameraOff();
      this.isValid = await content.startsWith("https://qrcode.u-power.app/");
      this.timeout(3000);
      if (this.isValid === true) {
        window.location = this.result;
        return;
      }

      if (this.isValid === false) {
        console.log("fail");
        await this.timeout(3000);
        this.isValid = undefined;
        this.turnCameraOn();
      }
    },
    async timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
    async onInit(promise) {
      this.loading = true;
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: 你必須開啟權限存取相機";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: 此裝置無相機";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: 不支援";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: 請檢查相機是否正在使用？";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        } else if (error.name === "InsecureContextError") {
          this.error =
            "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      } finally {
        this.loading = false;
      }
    },
    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: { x, y, width, height },
        } = detectedCode;
        ctx.lineWidth = 2;
        ctx.strokeStyle = "#007d86";
        ctx.strokeRect(x, y, width, height);
      }
    },
    changeHandler() {
      if (this.isInvalid) {
        if (this.connectorCode.trim().length > 0) {
          this.isInvalid = false;
        }
      }
    },
    turnCameraOff() {
      this.camera = "off";
    },
    turnCameraOn() {
      this.camera = "auto";
    },
    handleSubmit() {
      this.isProcessing = true;
      if (this.connectorCode.trim().length === 0) {
        this.isInvalid = true;
        this.isProcessing = false;
        return;
      }
      const payload = { queryConnectorcode: this.connectorCode };
      this.fetchConnector(payload);
      this.isProcessing = false;
    },
    handleSubmitInvalid() {
      this.connectorCode = "";
      this.isInvalid = false;
      this.isProcessing = false;
    },
    async fetchConnector({ queryConnectorcode }) {
      try {
        const { data, status } =
          await connectorAPI.connector.getByConnectorCode({
            connectorcode: queryConnectorcode,
          });
        if (!(status >= 200 && status < 300)) {
          throw new Error(status);
        }

        if (status === 204) {
          this.isInvalid = true;
          this.isProcessing = false;
          return;
        }
        if (status === 200) {
          this.$router.push({
            name: "Connector",
            query: {
              connectorId: data.connectorId,
              stationId: data.stationId,
            },
          });
        }
      } catch (error) {
        console.error(error.message);
      }
    },
  },
  computed: {
    validationPending() {
      return this.isValid === undefined && this.camera === "off";
    },
    validationSuccess() {
      return this.isValid === true;
    },
    validationFailure() {
      return this.isValid === false;
    },
  },
  watch: {
    connectorCode() {
      if (this.connectorCode.length === 0) {
        this.isInvalid = false;
      }
    },
  },
};
</script>

<style scoped>
.qrcode-stream-wrapper {
  position: absolute !important;
}
</style>
