import Vue from "vue";
import Vuex from "vuex";
import connectorAPI from "../apis/connector";
import stationAPI from "../apis/station";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    connector: {
      connectorId: "",
      boxId: "",
      connectorType: {
        connectorTypeId: 0,
        connectorType: "",
      },
      current: 0,
      position: 0,
      flag: 0,
      status: 1,
      available: 0,
    },
    station: {
      stationId: "",
      station: "",
      description: "",
      images: [],
      city: "",
      zip: "",
      address: "",
      latitude: "",
      longitude: "",
      power: 0,
      boxes: [],
      flag: 1,
      available: 0,
    },
    charging: {
      averagePower: 0,
      box: "",
      boxId: "",
      cardId: "",
      chargingId: "",
      chargingType: 3,
      coins: 0,
      connector: "",
      connectorId: "",
      connectorType: "",
      createdTime: "",
      energy: 0,
      finished: 0,
      invoiceAmount: 0,
      invoiceCode: null,
      invoiceEmail: null,
      invoiceNumber: null,
      invoiceRandomNumber: null,
      invoiceTitle: null,
      invoiceType: 0,
      maxPower: 0,
      notification: 0,
      orderNo: "",
      paid: 2,
      paymentMethod: 4,
      points: 0,
      startTime: "",
      station: "",
      stationId: "",
      status: 0,
      stopTime: "",
      timeout: 300,
      unitPrice: 0,
      userId: "",
      vehicle: null,
      vehicleId: "",
    },
    isLoading: true,
    currentConnectorStatus: {
      connectorId: "",
      status: 0,
    },
  },
  mutations: {
    setConnector(state, connector) {
      state.connector = connector;
    },
    setStation(state, station) {
      state.station = station;
    },
    setCharging(state, charging) {
      state.charging = charging;
    },
    updateIsLoading(state, value) {
      state.isLoading = value;
    },
    setConnectorStatus(state, connector) {
      state.currentConnectorStatus = connector;
    },
  },
  actions: {
    async isLoading({ commit }, value) {
      try {
        commit("updateIsLoading", value);
        return true;
      } catch (error) {
        console.error(error.message);
        return false;
      }
    },
    async fetchConnector({ commit }, connectorid) {
      try {
        const { data, status } = await connectorAPI.connector.getByConnectorId(
          connectorid
        );
        if (!(status >= 200 && status < 300)) {
          throw new Error(status);
        }
        commit("setConnector", data);
        return true;
      } catch (error) {
        console.error(error.message);
        return false;
      }
    },
    async fetchConnectorByBoxCodeConnectorNo(
      { commit },
      chargebox,
      connectorno
    ) {
      try {
        const { data, status } =
          await connectorAPI.connector.getByBoxCodeConnectorNo(
            chargebox,
            connectorno
          );
        if (!(status >= 200 && status < 300)) {
          throw new Error(status);
        }
        commit("setConnector", data);
        return true;
      } catch (error) {
        console.error(error.message);
        return false;
      }
    },
    async fetchStation({ commit }, stationid) {
      try {
        const { data, status } = await stationAPI.station.get(stationid);
        if (!(status >= 200 && status < 300)) {
          throw new Error(status);
        }
        commit("setStation", data);
        return true;
      } catch (error) {
        console.error(error.message);
        commit("updateIsLoading", false);
        return false;
      }
    },
    async checkConnectorState({ commit }, connectorid) {
      try {
        const { data, status } = await connectorAPI.connector.getStatus({
          connectorid,
        });
        if (!(status >= 200 && status < 300)) {
          throw new Error(status);
        }
        commit("setConnectorStatus", data);
        return true;
      } catch (error) {
        console.error(error.message);
        return false;
      }
    },
    async updateChargingData({ commit }, data) {
      try {
        commit("setCharging", data);
        return true;
      } catch (error) {
        console.error(error.message);
        return false;
      }
    },
  },
  modules: {},
});
